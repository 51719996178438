import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { VscNewFile } from 'react-icons/vsc'
import { Url } from './Url';
import Tippy from '@tippyjs/react';

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const ListadoSolicitudes = () => {
    const [ solicitudes, setSolicitudes ] = useState([]);
    const { idSolicitud, solicitudSelected, notification, setSolicitudSelected, setTipoModal, setOpenVista, roles, idUsuario,sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaSolicitudes = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/solicitudesList.php',
                    method: 'POST',
                    data: formData,
                })
                setSolicitudes(response.data.results);
            } catch (e) {
                console.log(e)
            }        
        }
        consultaSolicitudes()
    },[idSolicitud,sucursales,notification])

    const asignarSolicitud = (id) => {
        solicitudes.forEach(element => {
            if (element.id===id)
            {
                setSolicitudSelected({
                    id:element.id,
                    numero:element.numero,
                    equipo:element.equipo,
                    descripcion:element.descripcion,
                    tipofalla:element.tipofalla,
                    numeroot:element.numeroot,
                    solicitante:element.solicitante,
                    prioridad:element.prioridad,
                    idprioridad:element.idprioridad,
                    fechacreacion:element.fechacreacion,
                    imagenes:element.imagenes
                })
                return
            }
        });
    }

    const nuevaOrden = (id) => {
        asignarSolicitud(id);
        setTipoModal(33);
        setOpenVista(true);
    }

    const verSolicitud = async (id) => {
        asignarSolicitud(id);
        setTipoModal(35)
        setOpenVista(true);
    }
    const editarSolicitud = async (id) => {
        asignarSolicitud(id);
        setTipoModal(36)
        setOpenVista(true);
        //setOpenVista(true);
    }
    const eliminarSolicitud = async (id) => {
        asignarSolicitud(id);
        setTipoModal(37)
        setOpenVista(true);
    }

    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>Nº Solicitud</th>
                    <th className='border-gray-400 p-2'>Equipo</th>
                    <th className='border-gray-400 p-2'>Descripción</th>
                    <th className='border-gray-400 p-2'>Solicitante</th>
                    <th className='border-gray-400 p-2'>Nº de OT</th>
                    <th className='border-gray-400 p-2'>Prioridad</th>
                    <th className='border-gray-400 p-2'>Fecha de Creación</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof solicitudes !== 'undefined' ? (
                    solicitudes.map((item, index) => (
                    <tr 
                        key={item.id}
                        className={ classNames(solicitudSelected.id===item.id ? 'bg-blue-200' : index%2===0 ? 'bg-white hover:bg-slate-200':'bg-slate-100 hover:bg-slate-200', 'hover:cursor-pointer border-b border-gray-200')}
                        onClick={()=>asignarSolicitud(item.id)}
                    >
                        <td className='border-gray-400 p-2 text-center'>{item.numero}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.equipo}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.descripcion}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.solicitante}</td>                        
                        <td className='border-gray-400 p-2 text-center'>{item.numeroot}</td>                        
                        <td className='border-gray-400 p-2 text-center'>{item.prioridad}</td>                        
                        <td className='border-gray-400 p-2 text-center'>{item.fechacreacion}</td>
                        <td className='p-1 flex justify-center'>
                            {
                                !item.numeroot && (
                                <Tippy
                                    content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Generar OT</span> }
                                >
                                    <button
                                        className='bg-orange-500 p-3 rounded-md text-white'
                                        onClick={() => nuevaOrden(item.id)}
                                    >
                                        <VscNewFile/>
                                    </button>    
                                </Tippy>
                                )
                            }
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Ver Detalle de Solicitud</span> }
                            >
                                <button
                                    className='bg-indigo-500 p-3 rounded-md text-white ml-3'
                                    onClick={() => verSolicitud(item.id)}
                                >
                                    <FaEye/>
                                </button>
                            </Tippy>
                            { (roles.includes("1") || roles.includes("28")) && (
                            <>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Editar Solicitud</span> }
                            >
                                <button
                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => editarSolicitud(item.id)}
                                >
                                    <FaPen/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar Solicitud</span> }
                            >
                                <button
                                    className='bg-red-500 p-3 rounded-md text-white ml-3'
                                    onClick={() => eliminarSolicitud(item.id)}
                                >
                                    <FaTrash/>
                                </button>
                            </Tippy>
                            </>
                            )}
                        </td>                     
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoSolicitudes