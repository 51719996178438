import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const ListadoOtTecnicos = () => {

    const [ tecnicos, setTecnicos ] = useState([])
    //const [ idTecnico, setIdTecnico ] = useState()
    const { idOrden, ordenSelected } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaTecnicos = async () => {
            const formData=new FormData()
            formData.append('idot',ordenSelected.id)
            try{
                const response = await axios({
                    url: Url+'api/tecnicosOtListado.php',
                    method: 'POST',
                    data: formData,
                })
                setTecnicos(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaTecnicos()
    },[idOrden])

    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-gray-300'>
                    <th className='border-gray-400 p-2 text-center'>Tecnico</th>
                    <th className='border-gray-400 p-2 text-center'>Especialidad</th>
                    <th className='border-gray-400 p-2 text-center'>Costo Hora</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof tecnicos !== 'undefined' ? (
                    tecnicos.map((item) => (
                    
                    <tr 
                        key={item.id}
                        className={('bg-white hover:bg-slate-100 border-b border-gray-200') }
                    >
                        <td className='border-gray-400 p-2 text-center'>{`${item.apellido}, ${item.nombre}`}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.especialidad}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.costo}</td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoOtTecnicos