import { useContext } from "react";
import { useSortableData } from "./useSortableData";
import ContextEstados from "../../context/Estados";
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import Tippy from '@tippyjs/react';


const TablaEquipos = props => {
    const { items, requestSort, sortConfig } = useSortableData(props.equipos);
    const { equipos, 
        equipoSelected,
        setEquipoSelected, 
        setTipoModal, 
        setOpenVista, 
        roles
    } = useContext(ContextEstados);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const asignarEquipo = (id) => {
        equipos.forEach(element => {
            if (element.id===id)
            {
                setEquipoSelected({
                    id:element.id,
                    codigo:element.codigo,
                    descripcion: element.descripcion,
                    ubicacion:element.nombreubicacion,
                    idubicacion:element.idubicacion,
                    tipo:element.nombretipo,
                    idtipo:element.idtipo,
                    imagen:element.imagen,
                    estadoequipo:element.estadoequipo,
                    idestado:element.idestado,
                    idarea:element.idarea
                })
                return
            }
        });
    }

    const verEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(1)
        setOpenVista(true);
    }
    const editarEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(2)
        setOpenVista(true);
    }
    const eliminarEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(3)
        setOpenVista(true);
    }

    return (
    <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1'>
        <thead>
            <tr className='uppercase bg-blue-400 text-white'>
                <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('codigo')}
                        className={getClassNamesFor('codigo')}
                    >
                        Código
                    </button>
                </th>
                <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('descripcion')}
                        className={getClassNamesFor('descripcion')}
                    >
                        Nombre
                    </button>
                </th>
                <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('nombreubicacion')}
                        className={getClassNamesFor('nombreubicacion')}
                    >
                        Ubicación
                    </button>
                </th>
                <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('codigo')}
                        className={getClassNamesFor('codigo')}
                    >
                        Tipo
                    </button>
                </th>
                <th className='border-gray-400 p-2'>

                        Estado
                </th>
                <th className='border-gray-400 p-2'>

                        Acciones
                </th>
            </tr>
        </thead>
        <tbody>
            {items.map((item,index) => (
                <tr 
                    key={item.id}
                    className={ `${equipoSelected.id===item.id ? 'bg-blue-200' : index%2===0 ? 'bg-white hover:bg-slate-200':'bg-slate-100 hover:bg-slate-200'} ' hover:cursor-pointer border-b border-gray-200 ')` }
                    onClick={()=>asignarEquipo(item.id)}
                >
                    <td className='border-gray-400 p-1 text-center'>{item.codigo}</td>
                    <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                    <td className='border-gray-400 p-1 text-center'>{item.nombreubicacion}</td>
                    <td className='border-gray-400 p-1 text-center'>{item.nombretipo}</td>
                    <td className='border-gray-400 p-1 text-center'><p className={`${item.idestado==='1' ? 'bg-green-300' : item.idestado==='2' ? 'bg-red-300':item.idestado==='3' ? 'bg-orange-300' : 'bg-red-300'} ' p-2 rounded-md '`}>{item.estadoequipo}</p></td>
                    <td className='p-1 flex justify-center'>
                                <Tippy
                                    content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Ver Detalle de Equipo</span> }
                                >
                                    <button
                                        className='bg-indigo-500 p-3 rounded-md text-white'
                                        onClick={() => verEquipo(item.id)}
                                    >
                                        <FaEye/>
                                    </button>
                                </Tippy>
                                { (roles.includes("1") || roles.includes("11")) && (
                                <>
                                <Tippy
                                    content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Editar Equipo</span> }
                                >
                                    <button
                                        className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                        onClick={() => editarEquipo(item.id)}
                                    >
                                        <FaPen/>
                                    </button>
                                </Tippy>
                                <Tippy
                                    content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar Equipo</span> }
                                >
                                    <button
                                        className='bg-red-400 p-3 rounded-md text-white ml-3'
                                        onClick={() => eliminarEquipo(item.id)}
                                    >
                                        <FaTrash/>
                                    </button>
                                </Tippy>
                                </>
                                )}
                            </td>
    
                </tr>
            ))}
        </tbody>
    </table>
  )
}

export default TablaEquipos