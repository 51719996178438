import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ListadoUbicaciones from '../components/ListadoUbicaciones';
import ContextEstados from '../context/Estados';
import { AiOutlinePlus } from 'react-icons/ai'


const Ubicaciones = () => {
    const { setOpenAlta, setFormAlta, buscarUbicacion, setBuscarUbicacion } = useContext(ContextEstados);
  
    const Alta = () => {
        setFormAlta(9);
        setOpenAlta(true)
    }

    return (
    <Layout 
        pagina="Ubicaciones"
    >
        <div className="w-full flex-1 bg-slate-500">
            <h1 className="mt-10 mb-5 text-white text-3xl text-center">Ubicaciones</h1>
            <div className='w-full mt-2 bg-white'>
                <div className='w-full h-16 py-2 px-10 bg-slate-400 flex'>
                    <button 
                        className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
                        onClick={() => Alta(true)}
                    >
                        <AiOutlinePlus className='text-2xl mr-2'/>
                        Agregar Ubicación
                    </button>
                    <input
                        type="text"
                        className='py-3 rounded-xl bg-white w-1/3 mx-auto px-3'
                        value={buscarUbicacion}
                        placeholder="Buscar Ubicacion"
                        onChange={(e)=>setBuscarUbicacion(e.target.value)}
                    />
                </div>
                <div className='w-full h-full p-10 bg-slate-200'>
                    <ListadoUbicaciones/>
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default Ubicaciones