import React, { useEffect, useContext } from 'react'
import { CSVLink } from "react-csv";
import axios from 'axios';
import { BsDownload } from "react-icons/bs"
import ContextEstados from '../context/Estados';
import { Url } from './Url';
import TablaRepuestos from './tables/TablaRepuestos';

const ListadoRepuestos = () => {
    const { idRepuesto, 
        repuestos, 
        buscarRepuesto, 
        setRepuestos, 
        verStock, 
        idUsuario, 
        sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaRepuestos = async () => {
            const formData=new FormData()
            formData.append('verStock',verStock)
            formData.append('busqueda',buscarRepuesto)
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/repuestosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setRepuestos(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaRepuestos()
    },[idRepuesto,buscarRepuesto,verStock,sucursales])

  

    return (
    <div>
        {typeof repuestos !== 'undefined' && (
        <>
        <CSVLink data={repuestos}>
            <button type='button' className='bg-green-600 text-white p-3 rounded-md flex hover:bg-green-500'>
                <p className='text-xl mr-2'>
                    <BsDownload className='mr-2'/>
                </p>
                    Exportar a Excel
            </button>
        </CSVLink>
        <TablaRepuestos repuestos={repuestos}/>
        </>)}
    </div>
    )
}

export default ListadoRepuestos