import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FEditSolicitud = () => {
    const [ prioridades, setPrioridades ] = useState([])
    const [ prioridad, setPrioridad ] = useState(0)
    const [ equipo, setEquipo ] = useState(0)
    const [ tiposFallas, setTiposFallas ] = useState([])
    const [ tipoFalla, setTipoFalla ] = useState(0)
    const { solicitudSelected, setSolicitudSelected, idSolicitud, equipos, setEquipos, setIdSolicitud, setOpenVista, idUsuario } = useContext(ContextEstados);
    

    let tipoImagen = "solicitudes";
    let idHtml = "imagenesSolicitud";
    const childRef = useRef()

    useEffect(()=>{
        const consultaEquipos = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/equiposListado.php',
                    method: 'POST',
                    data: formData,
                })
                setEquipos(response.data.results);
                setEquipo(response.data.results[0].id);
            } catch (e) {
                console.log(e)
            }        
        }
        if (!equipos[0])
        {
            consultaEquipos()
        }
        else {
            setEquipo(equipos[0].id)
        }
    },[])

    useEffect(()=>{
        const consultaPrioridades = async () => {
            const url = Url+'/api/lPrioridades.php';
            const resultado = await axios.get(url);
            setPrioridades(resultado.data.results);
            setPrioridad(solicitudSelected.prioridad)
        }
        consultaPrioridades()
    },[])

    useEffect(()=>{
        const consultaTipoFalla = async () => {
            const url = Url+'/api/tipoFallaList.php';
            const resultado = await axios.get(url);
            setTiposFallas(resultado.data.results);
            setTipoFalla(solicitudSelected.tipofalla)
        }
        consultaTipoFalla()
    },[])


    useEffect(()=>{
        if (idSolicitud!==0)
        {
            childRef.current.handleSubmit();
            
        }
    },[idSolicitud])

    const eliminarImagen = async () => {
        const formData=new FormData()
        formData.append('t',"solicitudes");
        formData.append('id',solicitudSelected.id);
        
        let oData={...solicitudSelected};
        oData.imagen=null;
        setSolicitudSelected(oData);
        try{
            const response = await axios({
                url: Url+'api/deleteimages.php',
                method: 'POST',
                data: formData,
            })
            setIdSolicitud(response.data.results.id);
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues:{
            descripcion: solicitudSelected.descripcion,
            solicitante: solicitudSelected.solicitante
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                    .required("Debe ingresar una descripción"),
            solicitante: Yup.string()
                    .required("Debe ingresar un solicitante")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',solicitudSelected.id);
            formData.append('descripcion',valores.descripcion);
            formData.append('tipofalla',tipoFalla);
            formData.append('solicitante',valores.solicitante);
            formData.append('equipo',equipo);
            formData.append('prioridad',prioridad);
            try{
                const response = await axios({
                    url: Url+'api/solicitudesSet.php',
                    method: 'POST',
                    data: formData,
                })
            
                setIdSolicitud(response.data.results.idsolicitud);
                resetForm({ values: ''});
            } catch (e) {
                console.log(e)
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>Editar Solicitud de Trabajo Nº {solicitudSelected.numero}</h2>
            <div className="sm:items-start mt-5">
                <label htmlFor="sEquipos" className="block text-gray-600 uppercase font-bold">Equipo</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sEquipos' 
                    onChange={(e)=>setEquipo(e.target.value)}
                    value={equipo}
                >
                    { typeof equipos !== "undefined" ? (
                        equipos.map(item => (
                            <option key={item.id} value={item.id}>{item.descripcion}</option>))
                    ) : null }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                <textarea 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Descripción de Solicitud" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                ></textarea>
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sFalla" className="block text-gray-600 uppercase font-bold">Tipo de Falla</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sFalla' value={tipoFalla} onChange={(e)=>setTipoFalla(e.target.value)}>
                    { typeof tiposFallas !== "undefined" ? (
                        tiposFallas.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="solicitante" className="block text-gray-600 uppercase font-bold">Solicitante</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="solicitante" 
                    placeholder="Solicitante del Trabajo" 
                    type="text" 
                    value={formik.values.solicitante}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.solicitante && formik.errors.solicitante ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.solicitante}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sPrioridades" className="block text-gray-600 uppercase font-bold">Prioridad</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sPrioridades' 
                    onChange={(e)=>setPrioridad(e.target.value)}
                    value={prioridad}
                >
                    { typeof prioridades !== "undefined" ? (
                        prioridades.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>
        </div>
        <div className="pb-10">
            { solicitudSelected.imagen ? (
                <div className='lg:w-1/6 md:w-1/4 w-1/2 p-2'>
                    <img src={`${Url}${solicitudSelected.imagen}`}/>
                    <button
                        type='button'
                        className='w-full bg-red-500 text-white rounded-b-md'
                        onClick={()=>eliminarImagen()}
                    >Eliminar</button>
                </div>
            ): (
                <SubirImagenes 
                    id={solicitudSelected.id} 
                    tipo={tipoImagen} 
                    ref={childRef}
                    idHtml={idHtml}
                />
            )}
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FEditSolicitud