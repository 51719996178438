import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FormVariable = () => {
    const [ unidades, setUnidades ] = useState([]);
    const [ checked, setChecked ] = useState(false);
    const [ unidad, setUnidad ] = useState(0)
    const [ equipos, setEquipos ] = useState([]);
    const [ equipo, setEquipo ] = useState(0);
    const { setIdVariable, setOpenAlta,idUsuario } = useContext(ContextEstados);
    
    useEffect(()=>{
        const consultaUnidades = async () => {
            const url = Url+'api/lUnidades.php';
            const resultado = await axios.get(url);
            setUnidades(resultado.data.results);
            setUnidad(resultado.data.results[0].id)
        }
        consultaUnidades()
    },[])

    useEffect(()=>{
        const consultaEquipos = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/equiposListado.php',
                    method: 'POST',
                    data: formData,
                })
                setEquipos(response.data.results);
                setEquipo(response.data.results[0].id);
                } catch (e) {
                console.log(e)
            }        
        }
        consultaEquipos()
        
    },[])
    const handleChange = () => {    
        setChecked(!checked);
    };
  
    const formik = useFormik({
        initialValues:{
            nombre: '',
            valorInicial: '',
            fechaInicial: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                    .required("El nombre es necesario"),
            valorInicial: Yup.number()
                    .typeError("Debe ingresar solo números")
                    .required("Debe ingresar un valor"),
            fechaInicial: Yup.date()
            .required("La fecha inicial es necesaria")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            if (checked)
            {
                formData.append('acumula',1)
            }
            else
            {
                formData.append('acumula',0)
            }
            formData.append('equipo',equipo)
            formData.append('nombre',valores.nombre);
            formData.append('valorinicial',valores.valorInicial);
            formData.append('unidad',unidad);
            formData.append('fechainicial',valores.fechaInicial);
            try{
                const response = await axios({
                    url: Url+'api/variablesAlta.php',
                    method: 'POST',
                    data: formData,
                })
            
            setIdVariable(response.data.results.idvariable);
            resetForm({ values: ''});
            setOpenAlta(false)
        } catch (e) {
            console.log(e)
        }

        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>Nueva Variable</h2>
            <div className="sm:items-start mt-5">
                <label htmlFor="sEquipos" className="block text-gray-600 uppercase font-bold">Equipo</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sEquipos' onChange={(e)=>setEquipo(e.target.value)}>
                    { typeof equipos !== "undefined" ? (
                        equipos.map(item => (
                            <option key={item.id} value={item.id}>{item.descripcion}</option>))
                    ) : null }
                </select>
            </div>
            <div className="sm:items-start">
                <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre de Variable</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="nombre" 
                    placeholder="Ingrese un nombre de Variable" 
                    type="text" 
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.nombre && formik.errors.nombre ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.nombre}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="valorInicial" className="block text-gray-600 uppercase font-bold">Valor Inicial</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="valorInicial" 
                    placeholder="Valor Inicial" 
                    type="text" 
                    value={formik.values.valorInicial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.valorInicial && formik.errors.valorInicial ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.valorInicial}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5 flex">
                <input
                    className='mr-3 mt-1 text-xl'
                    type="checkbox" 
                    id="acumula" 
                    onChange={handleChange} 
                />
                <label htmlFor="acumula" className="block text-gray-600 uppercase font-bold">Acumula</label>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sUnidades" className="block text-gray-600 uppercase font-bold">Unidad de medida</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sUnidades' onChange={(e)=>setUnidad(e.target.value)}>
                    { typeof unidades !== "undefined" ? (
                        unidades.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>
            <div className="sm:items-start">
                <label htmlFor="fechaInicial" className="block mt-5 text-gray-600 uppercase font-bold">Fecha Inicial</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="fechaInicial" 
                    placeholder="Ingrese un nombre de Variable" 
                    type="date" 
                    value={formik.values.fechaInicial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.fechaInicial && formik.errors.fechaInicial ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.fechaInicial}</p>
                    </div>
                ) : null }
            </div>

        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenAlta(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FormVariable