import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import FormActTareas from './FormActTareas'
import ListadoTareas from './ListadoTareas';
import FormActRepuestos from './FormActRepuestos';
import ListadoActRepuestos from './ListadoActRepuestos';


const Recursos = () => {
	const { recurso, setRecurso,setVistaRecursos } = useContext(ContextEstados);


	
	return (
		<div className="w-full">
			<h2 className="text-gray-500 text-3xl text-center">Recursos</h2>
			<div className='px-10'>
			<button
				className={recurso===1 ? "text-white text-xl mr-5 bg-slate-600 p-3 rounded-lg" : "text-white text-xl p-3 mr-5 bg-slate-300 rounded-lg"}
				onClick={()=>setRecurso(1)}
			>Tareas</button>
			<button
				className={recurso===2 ? "text-white text-xl mr-5 bg-slate-600 p-3 rounded-lg" : "text-white text-xl p-3 mr-5 bg-slate-300 rounded-lg"}
				onClick={()=>setRecurso(2)}
			>Repuestos</button>
			</div>
			<div className='w-full mt-5'>
			{ recurso===1 ? (
				<>
				<FormActTareas/>
				<ListadoTareas/>
				</>
			) : (
				<>
				<FormActRepuestos/>
				<ListadoActRepuestos show={1}/>
				</>
			)}
				<button
					type="button"
					className="w-1/3 sm:w-1/4 shadow-md p-3 mt-10 mx-8 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
					onClick={() => setVistaRecursos(0)}
				>
					Cerrar
				</button>
			</div>
		</div>
	)
}

export default Recursos