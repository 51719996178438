import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from './Url'


const FDeleteActividad = () => {
    const { setOpenVista, actividadSelected, setIdActividad } = useContext(ContextEstados);
    const eliminarActividad = async () => {
        const formData=new FormData()
        formData.append('id',actividadSelected.id);
        try{
            const response = await axios({
                url: Url+'api/actividadesDelete.php',
                method: 'POST',
                data: formData,
            })
            console.log(response)
        setIdActividad(response.data.results.idactividad);
        setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'>Código: {actividadSelected.codigo}</p>
            <p className='text-xl mt-4 text-gray-600'>Descripción: {actividadSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'>Duración (Hs): {actividadSelected.tiempototal}</p>
            <p className='text-xl mt-4 text-gray-600'>Costo: {actividadSelected.costototal}</p>
            <p className='text-xl mt-4 text-gray-600'>Frecuencia: {actividadSelected.valorperiodo}</p>
            <p className='text-xl mt-4 text-gray-600'>Unidad: {actividadSelected.unidad}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => eliminarActividad()}
                >
                    Eliminar
                </button>

                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FDeleteActividad