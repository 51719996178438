import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';

const ListEquiposActividades = () => {
    const [ eqsActs, setEqsActs ] = useState([]);
    const { idEqAct, setEqActSelected, setTipoModal, setOpenVista, idUsuario, planSelected, sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaEqAct = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            formData.append('plan',planSelected.id)
            try{
                const response = await axios({
                    url: Url+'api/eqActList.php',
                    method: 'POST',
                    data: formData,
                })
                setEqsActs(response.data.results);
                
                } catch (e) {
                console.log(e)
            }
        }
        consultaEqAct()
    },[idEqAct])

    const asignarEqAct = (id) => {
        eqsActs.forEach(element => {
            if (element.id===id)
            {
                setEqActSelected({
                    id:element.id,
                    idequipo:element.idequipo,
                    equipo:element.equipo,
                    idactividad:element.idactividad,
                    actividad:element.actividad,
                    valorperiodo:element.valorperiodo,
                    fechainicio:element.fechainicio,
                    valorinicio:element.valorinicio,
                    unidad:element.unidad
                })
                return
            }
        });
    }

    const verEqActividad = async (id) => {
        asignarEqAct(id);
        setTipoModal(25)
        setOpenVista(true);
    }
    const editarEqActividad = async (id) => {
        asignarEqAct(id);
        setTipoModal(26)
        setOpenVista(true);
    }
    const eliminarEqActividad = async (id) => {
        asignarEqAct(id);
        setTipoModal(27)
        setOpenVista(true);
    }


    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-gray-300'>
                    <th className='border-gray-400 p-2'>Equipo</th>
                    <th className='border-gray-400 p-2'>Actividad</th>
                    <th className='border-gray-400 p-2'>Frecuencia</th>
                    <th className='border-gray-400 p-2'>Fecha inicio</th>
                    <th className='border-gray-400 p-2'>Unidad</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof eqsActs !== 'undefined' ? (
                    eqsActs.map((item) => (
                    <tr 
                        key={item.id}
                        className={'bg-white hover:bg-slate-100 hover:cursor-pointer'}
                    >
                        <td className='border-gray-400 p-2 text-center'>{item.equipo}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.actividad}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.valorperiodo}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.fechainicio}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.unidad}</td>
                        <td className='p-2 flex justify-center'>
                            <button
                                className='bg-indigo-500 p-3 rounded-md text-white text-3xl ml-3'
                                onClick={() => verEqActividad(item.id)}
                            >
                                <FaEye/>
                            </button>
                            <button
                                className='bg-gray-400 p-5 rounded-md text-white ml-3'
                                onClick={() => editarEqActividad(item.id)}
                            >
                                <FaPen/>
                            </button>
                            <button
                                className='bg-red-400 p-5 rounded-md text-white ml-3'
                                onClick={() => eliminarEqActividad(item.id)}
                            >
                                <FaTrash/>
                            </button>
                        </td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListEquiposActividades