import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus } from 'react-icons/ai'
import ListadoSolicitudes from '../components/ListadoSolicitudes';


const Solicitudes = () => {
	const { setOpenAlta, setFormAlta } = useContext(ContextEstados);

	const Alta = () => {
		setFormAlta(8);
		setOpenAlta(true)
	}
	
	return (
		<Layout 
			pagina="Solicitudes de Trabajo"
		>
			<div className="w-full flex-1 bg-slate-500">
				<h1 className="mt-10 mb-5 text-white text-3xl text-center">Solicitudes de Trabajo</h1>
				<div className='w-full min-h-screen mt-2 bg-white'>
					<div className='w-full h-16 py-2 px-10 bg-slate-400'>						
						<button 
							className='bg-indigo-600 hover:bg-indigo-400 p-3 shadow-lg rounded-md text-white uppercase flex'
							onClick={() => Alta(true)}
						>
							<AiOutlinePlus className='text-2xl mr-2'/>
							Nueva Solicitud
						</button>
					</div>
					<div className='w-full h-full p-10 bg-slate-200'>
						<ListadoSolicitudes/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Solicitudes