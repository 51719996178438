import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const CostoMO = () => {

    //const [ costoMO, setCostoMO ] = useState([])
    const { idOrden, ordenSelected, costoMOOT, setCostoMOOT } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaCostoMO = async () => {
            const formData=new FormData()
            formData.append('idot',ordenSelected.id)
            try{
                const response = await axios({
                    url: Url+'api/costoMO.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setCostoMOOT(response.data.results.total);
            } catch (e) {
                console.log(e)
            }
        }
        consultaCostoMO()
    },[idOrden])

    return (
        <div>
            {costoMOOT}
        </div>
    )

}

export default CostoMO