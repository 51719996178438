import { useContext, useEffect } from 'react'
import ContextEstados from '../context/Estados';

const Alerta = props => {
    const AuthContext = useContext(ContextEstados);
    const { mensaje, setMensaje } = AuthContext;

    useEffect(()=>{
        const showMensaje = () => {
            if (mensaje!==""){
                setTimeout(() => { 
                    setMensaje("");
                }, 3000)
            }
        }
        showMensaje()
    },[mensaje])
    return (
        <>
        { mensaje!=="" && (
        <div className='absolute w-full'>
            <div className={`${props.tipo==='success' ? 'bg-green-300 text-green-700':'bg-orange-300 text-orange-700'} ' uppercase text-bold text-center p-3 w-1/2 mx-auto rounded-xl z-50 '`}>
                {mensaje}
            </div>
        </div>
        )}
        </>
    );
}
 
export default Alerta;