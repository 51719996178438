import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';

const ListadoMedidores = () => {
    const [ variables, setVariables ] = useState([]);
    const [ color, setColor ] = useState(false);
    const { idVariable } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaVariables = async () => {
            const url =`${Url}/api/medListado.php`;
            const resultado = await axios.get(url);
            setVariables(resultado.data.results);
        }
        consultaVariables()
    },[idVariable])

   
    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-gray-300'>
                    <th className='border-gray-400 p-2'>Equipo</th>
                    <th className='border-gray-400 p-2'>Nombre</th>
                    <th className='border-gray-400 p-2'>Unidad</th>
                    <th className='border-gray-400 p-2'>Valor</th>
                    <th className='border-gray-400 p-2'>Fecha</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof variables !== 'undefined' ? (
                    variables.map((variable) => (
                    
                    <tr 
                        key={variable.id}
                        className={ ('bg-white hover:bg-slate-100 border-b border-gray-200') }
                    >
                        <td className='border-gray-400 p-2 text-center'>{variable.equipo}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.nombre}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.unidad}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.valor}</td>
                        <td className='border-gray-400 p-2 text-center'>{variable.fecha}</td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoMedidores