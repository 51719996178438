import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import ListadoOrdenes from '../components/ListadoOrdenes';
import FEditOrden from '../components/FEditOrden';


const Ordenes = () => {
    const { estadoOrden,setEstadoOrden, vistaOrdenes } = useContext(ContextEstados);
    
    return (
        <Layout 
        pagina="Órdenes de Trabajo"
        >
            <div className="w-full flex-1 bg-slate-500">
                <h1 className="mt-10 mb-5 text-white text-3xl text-center">Órdenes de Trabajo</h1>
                <div className='px-10 py-1 bg-black/5'>
                    <button 
                        className={`${estadoOrden===1 && "rounded-lg bg-slate-600"} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
                        onClick={()=>setEstadoOrden(1)}
                    >Trabajos Pendientes</button>
                    <button 
                        className={`${estadoOrden===2 && "rounded-lg bg-slate-600"} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
                        onClick={()=>setEstadoOrden(2)}
                    >Trabajos Cerrados</button>
                </div>
                { estadoOrden===1 ? (vistaOrdenes===0 ? (
                <div className='w-full h-screen mt-2 bg-white'>
                    <div className='w-full h-full p-10 bg-slate-200'>
                        <ListadoOrdenes/>
                    </div>
                </div>
                ): vistaOrdenes===1 ? (
                <div className='w-full h-screen mt-2 bg-white'>
                    <div className='w-full h-full p-10 bg-slate-200'>
                        <FEditOrden/>
                    </div>
                </div>
                ):null):(
                <div className='w-full h-screen mt-2 bg-white'>
                    <div className='w-full h-full p-10 bg-slate-200'>
                        <ListadoOrdenes/>
                    </div>
                </div>
                )}
            </div>       
        </Layout>
    )
}

export default Ordenes