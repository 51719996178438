import { useContext } from 'react'
import ContextEstados from '../context/Estados'
import axios from 'axios';
import { Url } from './Url';

const FormChSucursal = () => {
    const { sucursales, setSucursales, idUsuario, setOpenVista } = useContext(ContextEstados);

    const chSucursal = async (id) => {
        const formData = new FormData();
        formData.append("id",id);
        formData.append("idusuario",idUsuario);
        try{
            const response = await axios({
                url: Url+'api/chSucursal.php',
                method: 'POST',
                data: formData,
            })
            //console.log (response.data.results)
            setSucursales(response.data.results.sucursales);
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
            <h3 className='text-center mb-10 text-gray-500 text-xl'>Seleccione sucursal predeterminada</h3>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sSucursal' onChange={(e)=>chSucursal(e.target.value)}>
                    { typeof sucursales !== "undefined" ? (
                        sucursales.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            <button
                type="button"
                className="w-1/3 sm:w-1/4 mt-10 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
            >
                Salir
            </button>
        </>
    )
}

export default FormChSucursal