import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewPlan = () => {
    const { setOpenVista, planSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nombre: </span>{planSelected.nombre}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de Inicio: </span>{planSelected.fechainicio}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de Fin: </span>{planSelected.fechafin}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Tiempo Total: </span>{planSelected.tiempototal} Hs</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Costo Total: </span>$ {planSelected.costototal}</p>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewPlan