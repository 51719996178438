import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import SubirImagenes from './SubirImagenes'
import { Url } from './Url'

const FEditEquipo = () => {
    const [ tiposEquipos, setTiposEquipos ] = useState([]);
    const [ tipo, setTipo ] = useState(0)
    const [ areas, setAreas ] = useState([]);
    const [ area, setArea ] = useState(0)
    const [ ubicaciones, setUbicaciones ] = useState([]);
    const [ ubicacion, setUbicacion ] = useState(0)
    const [ estados, setEstados ] = useState([]);
    const [ estado, setEstado ] = useState(0)
    const { idEquipo, setIdEquipo,equipoSelected, setEquipoSelected, setOpenVista, idUsuario } = useContext(ContextEstados);
    

    let tipoImagen = "equipos";
    let idHtml = "imagenesEquipo";
    const childRef = useRef()

    useEffect(()=>{
        const consultaTipos = async () => {
            const url = Url+'api/tEqList.php';
            const resultado = await axios.get(url);
            setTiposEquipos(resultado.data.results);
            setTipo(equipoSelected.idtipo);
        }
        consultaTipos()
    },[])

    useEffect(()=>{
        setArea(equipoSelected.idarea)
        console.log("area "+equipoSelected.idarea)
        const consultaArea = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/areas.php',
                    method: 'POST',
                    data: formData,
                })
                setAreas(response.data.results)
            //setArea(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }        
        }
        consultaArea()
    },[])

    useEffect(()=>{
        const consultaUbica = async () => {
            if (area!==0)
            {
                const formData=new FormData()
                formData.append('idarea',area)
                try{
                    const response = await axios({
                        url: Url+'api/ubicaciones.php',
                        method: 'POST',
                        data: formData,
                    })
                    console.log(response)
                    setUbicaciones(response.data.results)
                setUbicacion(response.data.results[0].id)
                } catch (e) {
                    console.log(e)
                }    
            }
        }
        consultaUbica()
    },[area])

    useEffect(()=>{
        setEstado(equipoSelected.idestado)
        const consultaEstados = async () => {
            const formData=new FormData()
            try{
                const response = await axios({
                    url: Url+'api/estadosEq.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setEstados(response.data.results)
            } catch (e) {
                console.log(e)
            }    
        }
        consultaEstados()
    },[])

    useEffect(()=>{
        if (idEquipo!==0)
        {
            if (typeof childRef.current!=="undefined")
            {
                childRef.current.handleSubmit();
            }
        }
    },[idEquipo])

    const eliminarImagen = async () => {
        const formData=new FormData()
        formData.append('t',"equipos");
        formData.append('id',equipoSelected.id);
        
        let oData={...equipoSelected};
        oData.imagen=null;
        setEquipoSelected(oData);
        try{
            const response = await axios({
                url: Url+'api/deleteimages.php',
                method: 'POST',
                data: formData,
            })
            setIdEquipo(response.data.results.id);
            //setIdEquipo(0);
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        initialValues:{
            codigo: equipoSelected.codigo,
            descripcion: equipoSelected.descripcion
        },
        validationSchema: Yup.object({
            codigo: Yup.string()
                    .required("El código es necesario"),
            descripcion: Yup.string()
                    .required("Debe ingresar un nombre de equipo")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',equipoSelected.id);
            formData.append('codigo',valores.codigo);
            formData.append('descripcion',valores.descripcion);
            formData.append('tipo',tipo)
            formData.append('estado',estado)
            formData.append('ubicacion',ubicacion)
            try{
                const response = await axios({
                    url: Url+'api/equiposSet.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
            setIdEquipo(response.data.results.idequipo);
            setOpenVista(false);
            } catch (e) {
                console.log(e)
            }
        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <div className="sm:items-start">
                <label htmlFor="codigo" className="block mt-5 text-gray-600 uppercase font-bold">Código</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="codigo" 
                    placeholder="Ingrese un código" 
                    type="text" 
                    value={formik.values.codigo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.codigo && formik.errors.codigo ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.codigo}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Nombre</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="descripcion" 
                    placeholder="Nombre de Equipo" 
                    type="text" 
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                { formik.touched.descripcion && formik.errors.descripcion ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.descripcion}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sTipos" className="block text-gray-600 uppercase font-bold">Tipo de Equipo</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300'
                    id='sTipos' 
                    onChange={(e)=>setTipo(e.target.value)}
                    value={tipo}
                >
                    {
                        tiposEquipos.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>
                        ))
                    }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sArea" className="block text-gray-600 uppercase font-bold">Area</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sArea' onChange={(e)=>setArea(e.target.value)}>
                    { typeof areas !== "undefined" ? (
                        areas.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>))
                    ) : null }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sUbicacion" className="block text-gray-600 uppercase font-bold">Ubicación</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sUbicacion' 
                    onChange={(e)=>setUbicacion(e.target.value)}
                    value={ubicacion}
                >
                    {
                        typeof ubicaciones !== "undefined" && (
                        ubicaciones.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>
                        )))
                    }
                </select>
            </div>
            <div className="sm:items-start mt-5">
                <label htmlFor="sUbicacion" className="block text-gray-600 uppercase font-bold">Estado de Equipo</label>
                <select 
                    className='py-2 px-3 w-full rounded bg-slate-300' 
                    id='sEstado' 
                    onChange={(e)=>setEstado(e.target.value)}
                    value={estado}
                >
                    {
                        typeof estados !== "undefined" && (
                        estados.map(item => (
                            <option key={item.id} value={item.id}>{item.nombre}</option>
                        )))
                    }
                </select>
            </div>
        </div>
        <div className="pb-10">
            { equipoSelected.imagen ? (
                <div className='lg:w-1/6 md:w-1/4 w-1/2 p-2'>
                    <img src={`${Url}${equipoSelected.imagen}`}/>
                    <button
                        type='button'
                        className='w-full bg-red-500 text-white rounded-b-md'
                        onClick={()=>eliminarImagen()}
                    >Eliminar</button>
                </div>
            ): (
                <SubirImagenes 
                    id={equipoSelected.id} 
                    tipo={tipoImagen} 
                    ref={childRef}
                    idHtml={idHtml}
                />
            )}
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FEditEquipo