import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import axios from 'axios';
import { Url } from './Url'

const FDeleteSolicitud = () => {
    const { setIdSolicitud,setOpenVista, solicitudSelected } = useContext(ContextEstados);
    const eliminarSolicitud = async () => {
        const formData=new FormData()
        formData.append('id',solicitudSelected.id);
        try{
            const response = await axios({
                url: Url+'api/solicitudesDelete.php',
                method: 'POST',
                data: formData,
            })
            setIdSolicitud(response.data.results.idsolicitud);
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'>Nº de Solicitud: {solicitudSelected.numero}</p>
            <p className='text-xl mt-4 text-gray-600'>Equipo: {solicitudSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'>Descripción: {solicitudSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'>Solicitante: {solicitudSelected.solicitante}</p>
            <p className='text-xl mt-4 text-gray-600'>Nº de OT: {solicitudSelected.ot}</p>
            <p className='text-xl mt-4 text-gray-600'>Prioridad: {solicitudSelected.prioridad}</p>
            <p className='text-xl mt-4 text-gray-600'>Fecha de Creación: {solicitudSelected.fechacreacion}</p>
            <div className='w-1/2 mx-auto'>
                { solicitudSelected.imagen && (
                <img className='w-full object-cover' src={`${Url}${solicitudSelected.imagen}`} />
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => eliminarSolicitud()}
                >
                    Eliminar
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDeleteSolicitud