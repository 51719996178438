import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus } from 'react-icons/ai'
import ListadoPlaneamiento from '../components/ListadoPlaneamiento';
import ListadoPlRepuestos from '../components/ListadoPlRepuestos';
import axios from 'axios';
import { Url } from '../components/Url';
import Alerta from '../components/Alerta';

const Planeamiento = () => {
    const { setIdEqAct,vistaPlaneamiento, planeamSelected, setVistaPlaneamiento, roles, idUsuario, setMensaje, setPlaneamSelected } = useContext(ContextEstados);
    
    const crearOT = async() => {
        const formData=new FormData()
        formData.append('id',planeamSelected.id);
        formData.append('idusuario',idUsuario);
        try{
            const response = await axios({
                url: Url+'api/planeamientoOT.php',
                method: 'POST',
                data: formData,
            })
            //console.log(response)
            setIdEqAct(response.data.results.idot)
            setMensaje("La Órden de Trabajo ha sido creada")
            setPlaneamSelected({
                id:null
            })
        } catch (e) {
            console.log(e)
        }
    }

  
  return (
    <Layout 
      pagina="Planeamiento"
    >
        <div className="flex-1 bg-slate-500">
            <h1 className="mt-10 mb-5 text-white text-3xl text-center">Planeamiento</h1>
            <div className='w-full mt-2 px-10 py-1 bg-black/5'>
                <Alerta tipo={"success"}/>
                <button 
                    className={`${vistaPlaneamiento===0 && "bg-slate-600 rounded-lg"} " mr-5 text-white p-2 text-xl text-center"`}
                    onClick={()=>setVistaPlaneamiento(0)}
                >Actividades</button>
                <button 
                    className={`${vistaPlaneamiento===1 && "bg-slate-600 rounded-lg"} " mr-5 text-white p-2 text-xl text-center"`}
                    onClick={()=>setVistaPlaneamiento(1)}
                >Repuestos</button>
            </div>
            { vistaPlaneamiento===0 ? (
            <div className='w-full mt-2 bg-white'>
                <div className='w-full h-16 py-2 px-10 bg-slate-400'>
                    { (planeamSelected.id && (roles.includes("1") || roles.includes("25"))) && (
                    <button 
                        className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
                        onClick={()=>crearOT()}
                    >
                    <AiOutlinePlus className='text-2xl mr-2'/>
                    Crear OT
                    </button>
                    )}

                </div>
                <div className='w-full h-full px-10 py-2 bg-slate-200'>
                    <ListadoPlaneamiento/>
                </div>
            </div>
            ):(
            <div className='w-full h-screen mt-2 bg-white'>
                <div className='w-full h-16 py-2 px-10 bg-slate-400'>
                
                </div>
                <div className='w-full h-full p-10 bg-slate-200'>
                    <ListadoPlRepuestos/>
                </div>
            </div>
            )}
        </div>       
    </Layout>
  )
}

export default Planeamiento