import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import QRCode from "react-qr-code";
import { Url } from './Url'

const FViewEquipo = () => {
    //const { equipo } = useContext(ContextEquipos);
    const { setOpenVista, equipoSelected } = useContext(ContextEstados);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Código: </span>{equipoSelected.codigo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nombre: </span>{equipoSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Ubicación: </span>{equipoSelected.ubicacion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Tipo: </span>{equipoSelected.tipo}</p>
            <p className={`${equipoSelected.idestado==='1' ? 'bg-green-300' : equipoSelected.idestado==='2' ? 'bg-red-300' : equipoSelected.idestado==='3' ? 'bg-orange-300':'bg-red-300'} ' p-2 mt-4 mb-8 rounded-md text-xl text-center '`}>{equipoSelected.estadoequipo}</p>
            <div className='w-1/4 mx-auto'>
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={equipoSelected.id}
                    viewBox={`0 0 256 256`}
                />
            </div>
            <div className='w-1/2 mx-auto'>
                { equipoSelected.imagen && (
                <img className='w-full object-cover rounded-xl' src={`${Url}${equipoSelected.imagen}`} />
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                type="button"
                className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
                >
                Salir
                </button>
            </div>
        </div>
    )
}

export default FViewEquipo