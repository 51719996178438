import React, { useContext } from 'react'
import ContextEstados from '../context/Estados';
import { Url } from './Url'

const FViewSolicitud = () => {
    const { setOpenVista, setOpenModalImg, setFormImgView, setImageSelected, solicitudSelected } = useContext(ContextEstados);

    const handleImageSelected = (imagen) => {
        console.log(imagen)
		setImageSelected(imagen)
		setFormImgView(2)
		setOpenModalImg(true)
	}
    
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Nº de Solicitud: </span>{solicitudSelected.numero}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Equipo: </span>{solicitudSelected.equipo}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Descripción: </span>{solicitudSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Solicitante: </span>{solicitudSelected.solicitante}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nº de OT: </span>{solicitudSelected.ot}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Prioridad: </span>{solicitudSelected.prioridad}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha de Creación: </span>{solicitudSelected.fechacreacion}</p>
            <div className='w-full mx-auto'>
                { solicitudSelected.imagenes && (solicitudSelected.imagenes.map((item,index)=>(
                    <div className='w-1/3 p-3'>
                        <button
                            key={index}
                            onClick={()=>handleImageSelected(item.imagen)}
                        >
                            <img
                                className='w-full object-cover'
                                src={Url+item.imagen}
                            />
                        </button>
                    </div>
                    ))
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewSolicitud