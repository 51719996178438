import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus } from 'react-icons/ai'
import ListadoUsuarios from '../components/ListadoUsuarios';

const Tecnicos = () => {
  const { setOpenAlta, setFormAlta, setTipoUsuario, buscarUsuario, setBuscarUsuario } = useContext(ContextEstados);
  

  const Alta = () => {
    setTipoUsuario(2);
    setFormAlta(2);
    setOpenAlta(true);
  }
  return (
    <Layout 
      pagina="Técnicos"
    >
        <div className="w-full flex-1 bg-slate-500">
            <h1 className="mt-10 mb-5 text-white text-3xl text-center">Técnicos</h1>
            <div className='w-full h-screen mt-2 bg-white'>
              <div className='w-full h-16 py-2 px-10 bg-slate-400 flex'>
                <button 
                  className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex shadow-lg'
                  onClick={() => Alta(true)}
                >
                  <AiOutlinePlus className='text-2xl mr-2'/>
                  Agregar Técnico
                </button>
                <input
                  type="text"
                  className='py-3 rounded-xl bg-white w-1/3 mx-auto px-3'
                  value={buscarUsuario}
                  placeholder="Buscar Técnico"
                  onChange={(e)=>setBuscarUsuario(e.target.value)}
                />
              </div>
              <div className='w-full h-full p-10 bg-slate-200'>
                <ListadoUsuarios
                  tipoUsuarios={2}
                />
              </div>
            </div>
        </div>       
    </Layout>
  )
}

export default Tecnicos