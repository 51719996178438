import React, { useContext } from 'react'
import { useSortableData } from "./useSortableData";
import ContextEstados from '../../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import Tippy from '@tippyjs/react';

const TablaRepuestos = props => {
    const { items, requestSort, sortConfig } = useSortableData(props.repuestos);
    const { setRepuestoSelected, repuestos, setTipoModal, setOpenVista } = useContext(ContextEstados);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const asignarRepuesto = (id) => {
        repuestos.forEach(element => {
            if (element.id===id)
            {
                setRepuestoSelected({
                    id:element.id,
                    codigo:element.codigo,
                    descripcion: element.descripcion,
                    costo: element.costo,
                    nombreunidad:element.nombreunidad,
                    idunidad:element.idunidad,
                    nombremarca:element.nombremarca,
                    idmarca:element.idmarca,
                    imagen:element.imagen
                })
            }
        });
    }

    const verRepuesto = async (id) => {
        asignarRepuesto(id);
        setTipoModal(7)
        setOpenVista(true);
    }
    const editarRepuesto = async (id) => {
        asignarRepuesto(id);
        setTipoModal(8)
        setOpenVista(true);
    }
    const eliminarRepuesto = async (id) => {
        asignarRepuesto(id);
        setTipoModal(9)
        setOpenVista(true);
    }


    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('codigo')}
                        className={getClassNamesFor('codigo')}
                    >Código</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('descripcion')}
                        className={getClassNamesFor('descripcion')}
                    >Descripción</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('costo','number')}
                        className={getClassNamesFor('costo')}
                    >Costo</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('nombremarca')}
                        className={getClassNamesFor('nombremarca')}
                    >Marca</button></th>
                    <th className='border-gray-400 p-2'>
                    <button
                        type="button"
                        onClick={() => requestSort('nombreunidad')}
                        className={getClassNamesFor('nombreunidad')}
                    >Unidad</button></th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof items !== 'undefined' ? (
                    items.map((item,index) => (
                    
                    <tr 
                        key={item.id}
                        className={`hover:bg-slate-200 border-b border-gray-200 ${index%2===0 ?'bg-white':'bg-slate-100'}`}
                    >
                        <td className='border-gray-400 p-1 text-center'>{item.codigo}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.costo}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.nombremarca}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.nombreunidad}</td>
                        <td className='p-1 flex justify-center'>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Ver Repuesto</span> }
                            >
                                <button
                                    className='bg-indigo-500 p-3 rounded-md text-white'
                                    onClick={() => verRepuesto(item.id)}
                                >
                                    <FaEye/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Editar Repuesto</span> }
                            >
                                <button
                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => editarRepuesto(item.id)}
                                >
                                    <FaPen/>
                                </button>
                            </Tippy>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar Repuesto</span> }
                            >
                                <button
                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => eliminarRepuesto(item.id)}
                                >
                                    <FaTrash/>
                                </button>
                            </Tippy>
                        </td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default TablaRepuestos