import React, { useState, useContext, useRef } from 'react'
import ContextEstados from '../context/Estados';
import SumActRepuestos from './SumActRepuestos';
import SumSolRepuestos from './SumSolRepuestos';
import ReactToPrint from 'react-to-print';
import ListadoOtTecnicos from './ListadoOtTecnicos';
import ListadoActRepuestos from './ListadoActRepuestos';
import ListadoSolRepuestos from './ListadoSolRepuestos';
import CostoMO from './CostoMO';
import { FaPrint } from 'react-icons/fa'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Url } from './Url';

const FViewOrden = () => {
    const [ view, setView ] = useState(true)
    const { ordenSelected, setOpenVista, costoMOOT, costoRepOT } = useContext(ContextEstados);
    const componentRef = useRef();
   
    return (
    <div
        className="w-full mx-auto rounded-2xl bg-white"
    >
        <div className='print:p-5' ref={componentRef}>
        <img className='w-1/6 object-cover' src={`${Url}img/logoPresident.jpeg`} />
            <h1 className='text-center text-gray-500 text-3xl mb-2 print:text-gray-800 uppercase'>
                Orden de Trabajo
            </h1>
            <div className='w-full flex'>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Numero OT</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Cod. Eq</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Equipo</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Ubicacion</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Tipo Eq</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Prioridad</p></div>
            </div>
            <div className='w-full flex'>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.numero}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.equipoact ? (ordenSelected.equipocodact):(ordenSelected.equipocodsol) }</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.equipoact ? (ordenSelected.equipoact):(ordenSelected.equiposol) }</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.equipoact ? (ordenSelected.ubicacionact):(ordenSelected.ubicacionsol) }</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.equipoact ? (ordenSelected.tipoequipoact):(ordenSelected.tipoequiposol) }</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.prioridad}</p></div>
            </div>
            <div className='w-full flex'>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Estado</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Tipo</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>V. Control</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Val. Variable</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Supervisor</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Planeador</p></div>
            </div>
            <div className='w-full flex'>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.estado}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.tipo}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'></p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'></p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ ordenSelected.supervisor}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'></p></div>
            </div>
            <div className='w-full flex'>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Fecha Prog.</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Fecha Inicio</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>Fecha Fin</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>{ view && ('Costo M.O.') }</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>{ view && ('Costo Rep.') }</p></div>
                <div className='w-1/6 bg-slate-300'><p className='uppercase text-black text-center'>{ view && ('Costo Total') }</p></div>
            </div>
            <div className='w-full flex'>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ordenSelected.fechacreacion}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ordenSelected.fechainicio}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'></p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ view && (<CostoMO/>)}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ view && (ordenSelected.idactividad ? (<SumActRepuestos/>):(<SumSolRepuestos/>))}</p></div>
                <div className='w-1/6 bg-white'><p className='text-black text-center'>{ view && (parseFloat (costoMOOT)+ parseFloat (costoRepOT))}</p></div>
            </div>
            <div className="w-full sm:items-start mt-5">
                <h2 className='text-xl font-bold uppercase'>Trabajo a realizar</h2>
                <p className="block text-gray-600 uppercase font-bold">{ordenSelected.detalletrabajo}</p>
            </div>
            <div className="w-full sm:items-start mt-5">
                <h3 className='text-xl font-bold uppercase'>Técnicos</h3>
                <ListadoOtTecnicos/>
            </div>
            <div>
                <h3 className='text-xl font-bold uppercase'>Repuestos</h3>
            { ordenSelected.idactividad ? (
                <ListadoActRepuestos show={0}/>
            ):(
                <ListadoSolRepuestos show={0}/>
            )}                
            </div>
            <div className="w-full sm:items-start mt-5">
                <h2 className='text-xl font-bold uppercase'>Observaciones</h2>
                <p className="block text-gray-600 font-bold">{ordenSelected.observaciones!=='null' ? ordenSelected.observaciones : 'Sin observaciones'}</p>
            </div>
        </div>
        <div className="pb-1 mt-5">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
                <ReactToPrint
                    trigger={() => 
                        <button
                            type="button"
                            className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        >
                            <FaPrint className='mx-auto mt-1'/>
                            <p className='mx-auto text-center'>
                            Imprimir OT
                            </p>
                        </button>}
                    content={() => componentRef.current}  
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-green-500 text-white hover:bg-green-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setView(!view)}
                >
                    {
                        view ? (
                            <AiFillEyeInvisible className='mx-auto mt-1'/>
                        ) : (
                            <AiFillEye className='mx-auto mt-1'/>
                        )
                    }
                    
                </button>
            </div>
        </div>
    </div>
    )
}

export default FViewOrden