import { Fragment, useRef, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ContextEstados from '../context/Estados'
import FormUbicacion from './FormUbicacion';
import FormEquipo from './FormEquipo';
import FormUsuario from './FormUsuario';
import FormRepuestos from './FormRepuesto';
import FormVariable from './FormVariable';
import FormPlan from './FormPlan';
import FormActividad from './FormActividad';
import FormEqAct from './FormEqAct';
import FormSolicitud from './FormSolicitud';
import FormImportEquipos from './FormImportEquipos';
import FormImportRepuestos from './FormImportRepuestos';

const ModalAlta = ({form,boton, icon}) => {
  const { formAlta,openAlta, setOpenAlta } = useContext(ContextEstados)
  const cancelButtonRef = useRef(null)
 

  return (
    <>
		<Transition.Root show={openAlta} as={Fragment}>
			<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenAlta}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
						<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
						<Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:w-3/4 w-11/12">
							<div className="px-4 pt-2 sm:p-6">
								<div className="sm:flex sm:items-start">
									{formAlta===1 ? (
									<FormEquipo/>
									):formAlta===2 ?
									(
									<FormUsuario/>
									):formAlta===3 ?
									(
									<FormRepuestos/>
									):formAlta===4 ?
									(
									<FormVariable/>
									):formAlta===5 ?
									(
									<FormPlan/>
									):formAlta===6 ?
									(
									<FormActividad/>
									):formAlta===7 ?
									(
									<FormEqAct/>
									):formAlta===8 ?
									(
									<FormSolicitud/>
									):formAlta===9 ?
									(
									<FormUbicacion/>
									):formAlta===10 ?
									(
									<FormImportEquipos/>
									):formAlta===11 ?
									(
									<FormImportRepuestos/>
									):null}
								</div>
							</div>
						</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
   
    </>
  )
}

export default ModalAlta