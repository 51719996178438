import React, { useState, useEffect, useContext } from 'react'
import { CSVLink } from "react-csv";
import axios from 'axios';
import { BsDownload } from "react-icons/bs"
import { Url } from './Url';
import ContextEstados from '../context/Estados';
import TablaEquipos from './tables/TablaEquipos';
import TablaEquiposQR from './tables/TablaEquiposQR';


const ListadoEquipos = () => {
    
    const { equipos, 
        setEquipos,
        idEquipo,
        idUsuario,
        buscarEquipo,
        sucursales,
        viewQR
     } = useContext(ContextEstados);
    

    useEffect(()=>{
        const consultaEquipos = async () => {
            
            const formData=new FormData()
            formData.append('busqueda',buscarEquipo)
            formData.append('idusuario',idUsuario)
            try{
                const response = await axios({
                    url: Url+'api/equiposListado.php',
                    method: 'POST',
                    data: formData,
                })
                setEquipos(response.data.results);
            } catch (e) {
                console.log(e)
            }        
        }
        consultaEquipos()
    },[idEquipo,buscarEquipo])



    return (
        <>
        {typeof equipos !== 'undefined' && (
        <>
        {!viewQR && (
        <CSVLink data={equipos}>
            <button type='button' className='bg-green-600 text-white p-3 rounded-md flex hover:bg-green-500'>
                <p className='text-xl mr-2'>
                    <BsDownload className='mr-2'/>
                </p>
                    Exportar a Excel
            </button>
        </CSVLink>
        )}
        {!viewQR ? (
            <TablaEquipos equipos={equipos}/> 
        ):(
            <TablaEquiposQR/>
        )}
        </>)}
        </>
    );
  };

export default ListadoEquipos