import React, { useContext } from 'react'
import Layout from "../components/Layout";
import ContextEstados from '../context/Estados';
import { AiOutlinePlus } from 'react-icons/ai'
import { TbArrowBackUp } from 'react-icons/tb'
import ListadoPlanes from '../components/ListadoPlanes';
import ListadoActividades from '../components/ListadoActividades';
import ListEquiposActividades from '../components/ListEquiposActividades';
import { Url } from '../components/Url';
import axios from 'axios';


const Planes = () => {
	const { idUsuario,vistaPlanes,setVistaPlanes,planSelected,setActividadSelected,setOpenAlta, setFormAlta } = useContext(ContextEstados);

	const Alta = () => {
		setFormAlta(5);
		setOpenAlta(true)
	}
	const AltaEqAct = () => {
		setFormAlta(7);
		setOpenAlta(true)
	}

	const iniciarActividad = async () => {
		const formData=new FormData()
		formData.append('plan',planSelected.id);
		formData.append('usuario',idUsuario)
		try{
			const response = await axios({
				url: Url+'api/iniciarActividad.php',
				method: 'POST',
				data: formData,
			})
			setActividadSelected({
			id:response.data.results.idactividad
			})
			setFormAlta(6);
			setOpenAlta(true);
		} catch (e) {
			console.log(e)
		}
	}
	
	return (
		<Layout 
			pagina="Planes"
		>
			<div className="w-full flex-1 bg-slate-500">
				{vistaPlanes===0 && (
					<h1 className={"mt-5 mb-5 mx-10 text-white p-2 text-3xl text-center"}>Planes de Mantenimiento</h1>
				)}
				{vistaPlanes>=1 && (
					<div className='mt-10 px-10 py-1 bg-black/5'>
						<button 
							className="text-white bg-slate-400 hover:bg-slate-600 rounded-lg p-3 mr-10 text-2xl text-center" 
							onClick={()=>setVistaPlanes(0)}><TbArrowBackUp className=''/>
						</button>
						<button 
							className={`${vistaPlanes===1 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
							onClick={()=>setVistaPlanes(1)}
						>Actividades</button>
						<button 
							className={`${vistaPlanes===2 && "bg-slate-600 shadow-lg "} " text-white text-xl p-3 mr-10 hover:bg-white/10 rounded-lg "`}
							onClick={()=>setVistaPlanes(2)}
						>Equipos</button>
					</div>
				)}
				
				
				<div className='w-full h-screen mt-2 bg-white'>
				{ vistaPlanes===0 ? (
					<>
					<div className='w-full h-24 py-6 px-10 bg-slate-400'>
						<button 
						className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
						onClick={() => Alta()}
						>
						<AiOutlinePlus className='text-2xl mr-2'/>
						Nuevo Plan de Mantenimiento
						</button>
					</div>
					<div className='w-full h-full p-10 bg-slate-200'>
						<ListadoPlanes/>
					</div>
					</>
				) : vistaPlanes===1 ? (
					<>
					<div className='w-full h-24 py-2 px-10 bg-slate-400'>
						<h2 className='text-center text-2xl text-white'>{planSelected.nombre}</h2>
						<button 
						className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
						onClick={() => iniciarActividad()}
						>
						<AiOutlinePlus className='text-2xl mr-2'/>
						Nueva Actividad
						</button>
					</div>
					<div className='w-full h-full p-10 bg-slate-200'>
						<ListadoActividades/>
					</div>
					</>
				) : (
					<>
					<div className='w-full h-24 py-2 px-10 bg-slate-400'>
						<h2 className='text-center text-2xl text-white'>{planSelected.nombre}</h2>
						<button 
						className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex'
						onClick={() => AltaEqAct()}
						>
						<AiOutlinePlus className='text-2xl mr-2'/>
						Agregar Relación
						</button>
					</div>
					<div className='w-full h-full p-10 bg-slate-200'>
						<ListEquiposActividades/>
					</div>
					</>
				)}
				</div>
			</div>
		</Layout>
	)
}

export default Planes