import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';

const ListadoActividades = () => {
    const [ actividades, setActividades ] = useState([]);
    const { idActividad,planSelected, setActividadSelected, setTipoModal, setOpenVista } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaActividades = async () => {
            const url = `${Url}/api/actListado.php?plan=${planSelected.id}`;
            const resultado = await axios.get(url);
            
            setActividades(resultado.data.results);
        }
        consultaActividades()
    },[idActividad])

    const asignarActividad = (id) => {
        actividades.forEach(element => {
            if (element.id===id)
            {
                setActividadSelected({
                    id:element.id,
                    codigo:element.codigo,
                    descripcion:element.descripcion,
                    tiempototal:element.tiempototal,
                    costototal:element.costototal,
                    valorperiodo:element.valorperiodo,
                    unidad:element.unidad
                })
                return
            }
        });
    }
    const verActividad = async (id) => {
        asignarActividad(id);
        setTipoModal(15)
        setOpenVista(true);
    }
    const editarActividad = async (id) => {
        asignarActividad(id);
        setTipoModal(16)
        setOpenVista(true);
    }
    const eliminarActividad = async (id) => {
        asignarActividad(id);
        setTipoModal(17)
        setOpenVista(true);
    }

    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>Código</th>
                    <th className='border-gray-400 p-2'>Descripción</th>
                    <th className='border-gray-400 p-2'>Duración (Hs)</th>
                    <th className='border-gray-400 p-2'>Costo</th>
                    <th className='border-gray-400 p-2'>Frecuencia</th>
                    <th className='border-gray-400 p-2'>Unidad</th>
                    <th className='border-gray-400 p-2'>Procedimiento</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof actividades !== 'undefined' ? (
                    actividades.map((actividad,index) => (
                    <tr 
                        key={actividad.id}
                        className={`bg-white hover:bg-slate-200 hover:cursor-pointer ${index%2===0 ? 'bg-white':'bg-slate-100'}`}
                    >
                        <td className='border-gray-400 p-2 text-center'>{actividad.codigo}</td>
                        <td className='border-gray-400 p-2 text-center'>{actividad.descripcion}</td>
                        <td className='border-gray-400 p-2 text-center'>{actividad.tiempototal}</td>
                        <td className='border-gray-400 p-2 text-center'>{actividad.costototal}</td>
                        <td className='border-gray-400 p-2 text-center'>{actividad.valorperiodo}</td>
                        <td className='border-gray-400 p-2 text-center'>{actividad.unidad}</td>

                        <td className='border-gray-400 p-2 text-center'>{actividad.procedimiento && <a className='bg-blue-500 text-white p-2 rounded-md' href={`${Url}${actividad.procedimiento}`} target='_blank'>Ver Procedimiento</a>}</td>
                        <td className='p-2 flex justify-center'>
                            <button
                                className='bg-indigo-500 p-3 rounded-md text-white text-3xl ml-3'
                                onClick={() => verActividad(actividad.id)}
                            >
                                <FaEye/>
                            </button>
                            <button
                                className='bg-gray-400 p-5 rounded-md text-white ml-3'
                                onClick={() => editarActividad(actividad.id)}
                            >
                                <FaPen/>
                            </button>
                            <button
                                className='bg-red-400 p-5 rounded-md text-white ml-3'
                                onClick={() => eliminarActividad(actividad.id)}
                            >
                                <FaTrash/>
                            </button>
                        </td>
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoActividades