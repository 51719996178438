import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthState from './context/auth/authState'
import Index from './paginas/Index'
import Ubicaciones from './paginas/Ubicaciones'
import Equipos from './paginas/Equipos'
import Tecnicos from './paginas/Tecnicos'
import Repuestos from './paginas/Repuestos'
import Planes from './paginas/Planes'
import Planeamiento from './paginas/Planeamiento'
import Ordenes from './paginas/Ordenes'
import Solicitudes from './paginas/Solicitudes'
import AdministracionUsuarios from './paginas/AdministracionUsuarios'
import ContextEstados from './context/Estados'
import ModalAlta from './components/ModalAlta'
import ModalVista from './components/ModalVista'
import ModalImgVista from './components/ModalImgVista'
import Reporting from './paginas/Reporting'
import Perfil from './paginas/Perfil'
import BackNotifications from './components/BackNotifications'
import CreateNotification from './components/CreateNotification'

const App = () => {
  const [ itemSidebar, setItemSidebar ] = useState(0)
  const [ roles, setRoles ] = useState([])
  const [ tipoUsrLogin, setTipoUsrLogin ] = useState(0)
  const [ sucursales, setSucursales ] = useState([])
  const [ equipos, setEquipos ] = useState([]);
  const [ usuarios, setUsuarios ] = useState([]);
  const [ repuestos, setRepuestos ] = useState([]);
  const [ buscarEquipo, setBuscarEquipo ] = useState('');
  const [ buscarUsuario, setBuscarUsuario ] = useState('');
  const [ buscarRepuesto, setBuscarRepuesto ] = useState('');
  const [ ubicacionSelected, setUbicacionSelected ] = useState({})
  const [ idUbicacion, setIdUbicacion ] = useState(0);
  const [ buscarUbicacion, setBuscarUbicacion ] = useState('');
  const [ verStock, setVerStock ] = useState(0)
  const [ idEquipo, setIdEquipo ] = useState(0)
  const [ formAlta, setFormAlta ] = useState(0)
  const [ idUsuario, setIdUsuario ] = useState(0)
  const [ equipoSelected, setEquipoSelected ] = useState({});
  const [ usuarioSelected, setUsuarioSelected ] = useState({})
  const [ tipoUsuario, setTipoUsuario ] = useState(0)
  const [ repuestoSelected, setRepuestoSelected ] = useState({})
  const [ variableSelected, setVariableSelected ] = useState({})
  const [ planSelected, setPlanSelected ] = useState({})
  const [ actividadSelected, setActividadSelected ] = useState({})
  const [ idUsuarioNuevo, setIdUsuarioNuevo ] = useState(0)
  const [ idRepuesto, setIdRepuesto ] = useState(0)
  const [ idVariable, setIdVariable ] = useState(0)
  const [ idPlan, setIdPlan ] = useState(0)
  const [ idActividad, setIdActividad ] = useState(0)
  const [ idTarea, setIdTarea ] = useState(0)
  const [ tareaSelected, setTareaSelected ] = useState({})
  const [ idActRepuesto, setIdActRepuesto ] = useState(0)
  const [ actRepuestoSelected, setActRepuestoSelected ] = useState({})
  const [ idSolRepuesto, setIdSolRepuesto ] = useState(0)
  const [ solRepuestoSelected, setSolRepuestoSelected ] = useState({})
  const [ vistaPlanes, setVistaPlanes ] = useState(0)
  const [ vistaRecursos, setVistaRecursos ] = useState(0)
  const [ vistaPlaneamiento, setVistaPlaneamiento ] = useState(0)
  const [ vistaOrdenes, setVistaOrdenes ] = useState(0)
  const [ recurso, setRecurso ] = useState(1)
  const [ idEqAct, setIdEqAct ] = useState(0)
  const [ idOrden, setIdOrden ] = useState(0)
  const [ idSolicitud, setIdSolicitud ] = useState(0)
  const [ eqActSelected, setEqActSelected ] = useState({})
  const [ planeamSelected, setPlaneamSelected ] = useState({})
  const [ ordenSelected, setOrdenSelected ] = useState({})
  const [ estadoOrden,setEstadoOrden ] = useState(1)
  const [ solicitudSelected, setSolicitudSelected ] = useState({})
  const [ openAlta, setOpenAlta ] = useState(false)
  const [ openVista, setOpenVista ] = useState(false)
  const [ tipoModal, setTipoModal ] = useState(0);
  const [ listadoRoles, setListadoRoles ] = useState([])
  const [ costoRepOT, setCostoRepOT ] = useState(0)
  const [ costoMOOT, setCostoMOOT ] = useState(0)
  const [ mensaje, setMensaje ] = useState("")
  const [ notification, setNotification ] = useState("")
  const [ messageOut, setMessageOut ] = useState("")
  const [ openModalImg, setOpenModalImg ] = useState(false);
  const [ formImgView, setFormImgView ] = useState(0);
  const [ imageSelected, setImageSelected ] = useState('')
  const [ viewQR, setViewQR ] = useState(false)

	return (

		<AuthState>
		<ContextEstados.Provider
		value={{
			itemSidebar, setItemSidebar,
			roles, setRoles,
			tipoUsrLogin, setTipoUsrLogin,
			idUsuario, setIdUsuario,
			sucursales, setSucursales,
			equipos, setEquipos,
			usuarios, setUsuarios,
			buscarEquipo, setBuscarEquipo,
			buscarUsuario, setBuscarUsuario,
			repuestos, setRepuestos,
			buscarRepuesto, setBuscarRepuesto,
			ubicacionSelected, setUbicacionSelected,
			buscarUbicacion, setBuscarUbicacion,
			idUbicacion, setIdUbicacion,
			verStock, setVerStock,
			idEquipo,setIdEquipo,
			equipoSelected, setEquipoSelected,
			usuarioSelected, setUsuarioSelected,
			tipoUsuario, setTipoUsuario,
			repuestoSelected, setRepuestoSelected,
			variableSelected, setVariableSelected,
			planSelected, setPlanSelected,
			actividadSelected, setActividadSelected,
			formAlta, setFormAlta,
			openAlta, setOpenAlta,
			openVista, setOpenVista,
			idUsuarioNuevo, setIdUsuarioNuevo,
			idRepuesto, setIdRepuesto,
			idVariable, setIdVariable,
			idPlan, setIdPlan,
			idActividad, setIdActividad,
			idTarea, setIdTarea,
			tareaSelected, setTareaSelected,
			idActRepuesto, setIdActRepuesto,
			actRepuestoSelected, setActRepuestoSelected,
			idSolRepuesto, setIdSolRepuesto,
			solRepuestoSelected, setSolRepuestoSelected,
			vistaPlanes, setVistaPlanes,
			vistaRecursos, setVistaRecursos,
			vistaPlaneamiento, setVistaPlaneamiento,
			vistaOrdenes, setVistaOrdenes,
			recurso, setRecurso,
			idEqAct, setIdEqAct,
			idOrden, setIdOrden,
			eqActSelected, setEqActSelected,
			planeamSelected, setPlaneamSelected,
			ordenSelected, setOrdenSelected,
			estadoOrden,setEstadoOrden,
			idSolicitud, setIdSolicitud,
			solicitudSelected, setSolicitudSelected,
			tipoModal, setTipoModal,
			listadoRoles, setListadoRoles,
			costoRepOT, setCostoRepOT,
			costoMOOT, setCostoMOOT,
			mensaje, setMensaje,
			notification, setNotification,
			messageOut, setMessageOut,
			openModalImg, setOpenModalImg,
			formImgView, setFormImgView,
			imageSelected, setImageSelected,
			viewQR, setViewQR
		}}
		>
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Index />} />
				<Route path='/ubicaciones' element={<Ubicaciones />} />
				<Route path='/equipos' element={<Equipos />} />
				<Route path='/tecnicos' element={<Tecnicos />} />
				<Route path='/repuestos' element={<Repuestos />} />
				<Route path='/planes' element={<Planes />} />
				<Route path='/planeamiento' element={<Planeamiento />} />
				<Route path='/ordenes' element={<Ordenes />} />
				<Route path='/solicitudes' element={<Solicitudes />} />
				<Route path='/reporting' element={<Reporting />} />
				<Route path='/administracionUsuarios' element={<AdministracionUsuarios />} />
				<Route path='/perfil' element={<Perfil />} />
			</Routes>
			<ModalAlta/>
			<ModalVista/>
			<ModalImgVista/>
			<BackNotifications/>
			<CreateNotification/>
		</BrowserRouter>
		</ContextEstados.Provider>
		</AuthState>
	)
}

export default App
