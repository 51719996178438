import { useEffect, useContext } from 'react'
import io  from 'socket.io-client';
import ContextEstados from '../context/Estados';

const socket = io('http://186.158.210.127:4000')

const MessageOut = () => {
    const { idUsuario, messageOut, setMessageOut } = useContext(ContextEstados)

    useEffect(()=>{
		const sendNotification = () => {
            console.log("llega por aqui mismo")
			if (messageOut!=='')
			{
				socket.emit('message',messageOut,idUsuario)
				setMessageOut("")
			}
		}
		sendNotification()
	},[messageOut, idUsuario, setMessageOut])

    return (
        <></>
    )
}

export default MessageOut