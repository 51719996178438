import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url';
import Tippy from '@tippyjs/react';


const ListadoOrdenes = () => {
    const [ ordenes, setOrdenes ] = useState([]);
    const { idOrden,
        estadoOrden,
        ordenSelected,
        notification, 
        setOrdenSelected, 
        setActividadSelected, 
        setSolicitudSelected, 
        setTipoModal, 
        setVistaOrdenes, 
        setOpenVista, 
        idUsuario, 
        roles, 
        tipoUsrLogin,
        sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaOrdenes = async () => {
            const formData=new FormData()
            formData.append('estado',estadoOrden);
            formData.append('tipousuario',tipoUsrLogin);
            formData.append('idusuario',idUsuario);
            try{
                const resultado = await axios({
                    url: Url+'api/ordenesList.php',
                    method: 'POST',
                    data: formData,
                })
                setOrdenes(resultado.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaOrdenes()
    },[idOrden,estadoOrden,idUsuario,notification])

    const asignarOrden = (id) => {
        ordenes.forEach(element => {
            if (element.id===id)
            {
                if (element.idactividad)
                {
                    setActividadSelected({
                        id:element.idactividad
                    })
                }
                else
                {
                    setSolicitudSelected({
                        id:element.idsolicitud
                    })
                }
                setOrdenSelected({
                    id:element.id,
                    numero:element.numero,
                    equipoact:element.equipoact,
                    equiposol:element.equiposol,
                    idactividad:element.idactividad,
                    actividad:element.actividad,
                    idsolicitud:element.idsolicitud,
                    solicitud:element.solicitud,
                    fechacreacion:element.fechacreacion,
                    fechaprog:element.fechaprog,
                    horaprog:element.horaprog,
                    unidad:element.unidad,
                    detalletrabajo:element.detalletrabajo,
                    observaciones:element.observaciones,
                    tipo:element.tipo,
                    estado:element.estado,
                    prioridad:element.prioridad,
                    idestado:element.idestado,
                    idprioridad:element.idprioridad,
                    idtipo:element.idtipo,
                    idsupervisor:element.idsupervisor,
                    supervisor:element.supervisor,
                    idresponsable:element.idresponsable,
                    responsable:element.responsable,
                    equipocodact:element.equipocodact,
                    equipocodsol:element.equipocodsol,
                    ubicacionact:element.ubicacionact,
                    ubicacionsol:element.ubicacionsol,
                    tipoequipoact:element.tipoequipoact,
                    tipoequiposol:element.tipoequiposol,
                    horastrabajo:element.horastrabajo,
                    totalMO:element.totalMO,
                    totalRep:element.totalRep
                })
                return
            }
        });
    }

    const verOrden = async (id) => {
        asignarOrden(id);
        setTipoModal(30)
        setOpenVista(true);
    }
    const editarOrden = async (id) => {
        asignarOrden(id);
        setVistaOrdenes(1)
    }
    const eliminarOrden = async (id) => {
        asignarOrden(id);
        setTipoModal(31)
        setOpenVista(true);
    }


    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>Nº OT</th>
                    <th className='border-gray-400 p-2'>Equipo</th>
                    <th className='border-gray-400 p-2'>Descripción</th>
                    <th className='border-gray-400 p-2'>Fecha creación</th>
                    <th className='border-gray-400 p-2'>Fecha prog.</th>
                    <th className='border-gray-400 p-2'>Estado</th>
                    <th className='border-gray-400 p-2'>Fecha Estado</th>
                    <th className='border-gray-400 p-2'>Hora Estado</th>
                    <th className='border-gray-400 p-2'>Unidad</th>
                    <th className='border-gray-400 p-2'>Tipo</th>
                    <th className='border-gray-400 p-2'>Prioridad</th>
                    <th className='border-gray-400 p-2'>Acciones</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof ordenes !== 'undefined' ? (
                    ordenes.map((item, index) => (
                    <tr 
                        key={item.id}
                        className={`hover:bg-slate-200 border-b border-gray-200 hover:cursor-pointer ${index%2===0 ? 'bg-white':'bg-slate-100'}`}
                        onClick={()=>asignarOrden(item.id)}
                    >
                        <td className='border-gray-400 p-1 text-center'>{item.numero}</td>
                        <td className='border-gray-400 p-1 text-center'>{ item.equipoact ? (item.equipoact):(item.equiposol) }</td>
                        <td className='border-gray-400 p-1 text-center'>{ item.actividad ? (item.actividad):(item.solicitud) }</td>
                        <td className='border-gray-400 p-1 text-center'>{item.fechacreacion}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.fechaprog}</td>
                        <td className={`${item.idestado==='1' ? ' bg-orange-300 ' : item.idestado==='2' ? ' bg-yellow-300 ' : item.idestado==='3' ? ' bg-red-300 ' : ' bg-green-300 ' } ' border-gray-400 p-1 text-center '`}>{item.estado}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.fechaestado}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.horaestado}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.unidad}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.tipo}</td>
                        <td className='border-gray-400 p-1 text-center'>{item.prioridad}</td>
                        <td className='p-1 flex justify-center'>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Ver Detalle de OT</span> }
                            >
                                <button
                                    className='bg-indigo-500 p-3 rounded-md text-white'
                                    onClick={() => verOrden(item.id)}
                                >
                                    <FaEye/>
                                </button>
                            </Tippy>
                            { (estadoOrden===1 && (roles.includes("1") || roles.includes("26"))) && (
                            <>
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Editar OT</span> }
                            >
                                <button
                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                    onClick={() => editarOrden(item.id)}
                                    >
                                    <FaPen/>
                                </button>
                            </Tippy>
                            { (tipoUsrLogin!=='2' && tipoUsrLogin!=='4') && (
                            <Tippy
                                content={ <span className='bg-gray-600 text-white p-2 rounded-md'>Eliminar OT</span> }
                            >
                                <button
                                    className='bg-red-500 p-3 rounded-md text-white ml-3'
                                    onClick={() => eliminarOrden(item.id)}
                                >
                                    <FaTrash/>
                                </button>
                            </Tippy>

                            )}
                            </>
                            )}
                        </td>                     
                    </tr>
                    
                ))
                ) : ( null )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoOrdenes