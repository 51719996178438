import React, { useState,useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextEstados from '../context/Estados'
import { Url } from './Url'

const FormPlan = () => {
    //const [ tipo, setTipo ] = useState(1)
    //const [ prioridad, setPrioridad ] = useState(1)
    const { setIdPlan, setOpenAlta, idUsuario } = useContext(ContextEstados);
    
  
    const formik = useFormik({
        initialValues:{
            nombre: '',
            fechaInicio: '',
            fechaFin: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                    .required("El nombre es necesario"),
            fechaInicio: Yup.date()
            .required("La fecha de inicio es necesaria"),
            fechaFin: Yup.date()
            .required("La fecha de fin es necesaria")
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('nombre',valores.nombre);
            formData.append('fechainicio',valores.fechaInicio);
            formData.append('fechafin',valores.fechaFin);
            formData.append('idusuario',idUsuario);
            /*formData.append('tipo',tipo);
            formData.append('prioridad',prioridad);*/
            try{
                const response = await axios({
                    url: Url+'api/planesAlta.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setIdPlan(response.data.results.idplan);
                resetForm({ values: ''});
                setOpenAlta(false)
            } catch (e) {
                console.log(e)
            }

        }
    });
  return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className="transition-all w-full sm:p-3">
            <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Plan de Mantenimiento</h2>
            <div className="sm:items-start">
                <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="nombre" 
                    placeholder="Ingrese un nombre de Plan" 
                    type="text" 
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.nombre && formik.errors.nombre ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.nombre}</p>
                    </div>
                ) : null }
            </div>
            {/*}
            <div className="sm:items-start mt-5">
                <label htmlFor="sTipo" className="block text-gray-600 uppercase font-bold">Tipo de Plan</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sTipos' onChange={(e)=>setTipo(e.target.value)}>
                    <option value="1">Preventivo</option>
                    <option value="2">Correctivo</option>
                </select>
            </div>
            {*/}
            <div className="sm:items-start">
                <label htmlFor="fechaInicio" className="block mt-5 text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="fechaInicio" 
                    placeholder="Ingrese un nombre de Variable" 
                    type="date" 
                    value={formik.values.fechaInicio}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.fechaInicio && formik.errors.fechaInicio ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.fechaInicio}</p>
                    </div>
                ) : null }
            </div>
            <div className="sm:items-start">
                <label htmlFor="fechaFin" className="block mt-5 text-gray-600 uppercase font-bold">Fecha de Fin</label>
                <input 
                    className="py-2 px-3 w-full rounded bg-slate-300" 
                    id="fechaFin" 
                    placeholder="Ingrese un nombre de Variable" 
                    type="date" 
                    value={formik.values.fechaFin}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus
                />
                { formik.touched.fechaFin && formik.errors.fechaFin ? (
                    <div className='w-full block mb-2 text-red-500'>
                        <p>{formik.errors.fechaFin}</p>
                    </div>
                ) : null }
            </div>
            {/*}
            <div className="sm:items-start mt-5">
                <label htmlFor="sPrioridad" className="block text-gray-600 uppercase font-bold">Prioridad</label>
                <select className='py-2 px-3 w-full rounded bg-slate-300' id='sPrioridad' onChange={(e)=>setPrioridad(e.target.value)}>
                    <option value="1">Alta</option>
                    <option value="2">Media</option>
                    <option value="3">Baja</option>
                </select>
            </div>
            {*/}
        </div>
        <div className="pb-1">
            <div className="w-full rounded-lg px-3 flex justify-center">
                <input
                    type="submit"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                    value="Guardar Datos"
                />
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenAlta(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </form>               
  )
}

export default FormPlan