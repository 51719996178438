import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import ContextEstados from '../context/Estados';
import { Url } from './Url';


const ListadoPlaneamiento = () => {
    const [ planeamientos, setPlaneamientos ] = useState([]);
    const { idEqAct,planeamSelected, setPlaneamSelected, notification, idUsuario, sucursales } = useContext(ContextEstados);

    useEffect(()=>{
        const consultaPlaneamiento = async () => {
            const formData=new FormData()
            formData.append('idusuario',idUsuario);
            try{
                const resultado = await axios({
                    url: Url+'api/planeamList.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(resultado)
                setPlaneamientos(resultado.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaPlaneamiento()
    },[idEqAct,notification,sucursales])

    const asignarPlaneamiento = (id) => {
        planeamientos.forEach(element => {
            if (element.id===id)
            {
                setPlaneamSelected({
                    id:element.id
                })
                return
            }
        });
    }

    return (
    <div>
        <table className = 'table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
            <thead>
                <tr className='uppercase bg-blue-400 text-white'>
                    <th className='border-gray-400 p-2'>Equipo</th>
                    <th className='border-gray-400 p-2'>Actividad</th>
                    <th className='border-gray-400 p-2'>Frecuencia</th>
                    <th className='border-gray-400 p-2'>Unidad</th>
                    <th className='border-gray-400 p-2'>Fecha Planeamiento</th>
                </tr>
            </thead>
            <tbody >
            {
                typeof planeamientos !== 'undefined' && (
                    planeamientos.map((item,index) => (
                    <tr 
                        key={item.id}
                        className={ `${planeamSelected.id===item.id ? ' bg-blue-200 ' : item.fueradetermino===1 ? ' bg-red-200 text-red-700 ': item.fueradetermino===2 ? ' bg-orange-200 text-orange-700 ' : index%2===0  ? 'bg-white hover:bg-slate-200':'bg-slate-100 hover:bg-slate-200'},' hover:cursor-pointer border-b border-gray-200 '`}
                        onClick={()=>asignarPlaneamiento(item.id)}
                    >
                        <td className='border-gray-400 p-2 text-center'>{item.equipo}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.actividad}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.valorperiodo}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.unidad}</td>
                        <td className='border-gray-400 p-2 text-center'>{item.fecha}</td>
                    </tr>
                ))
                )
            }
            </tbody>
        </table>
    </div>
    )
}

export default ListadoPlaneamiento