import { useState, useEffect, useContext } from 'react'
import  io  from 'socket.io-client';
import ContextEstados from '../context/Estados';
const socket = io('http://186.158.210.127:4000')

const BackNotifications = () => {
    const [ message, setMessage ] = useState("");
    const { setNotification } = useContext(ContextEstados);


    useEffect(()=>{
        const receiveMessage = (message) => {
            setMessage(message)
            setNotification(message.body)
        }
        socket.on('message', receiveMessage)
        
        return () => {

            socket.off('message', receiveMessage)
        }
        
    },[message])


}

export default BackNotifications